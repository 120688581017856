body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat', sans-serif;
}

.navControl {
  color: #0084FB !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 15px !important;
}

.orangeColor {
  color: #FF5D00;
}

.nav-link {
  font-family: "Montserrat", sans-serif;
}