.App {
    text-align: center;
    background-color: whitesmoke;
    min-height: 100vh;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.title {
    color: #989898;
    margin-bottom: 0%;
    /*font-size: calc(45px + 2vmin);*/
    font-size: calc(40px + 2vmin);
    font-family: 'Montserrat', sans-serif;
    padding-top: 3%;
    padding-bottom: 3%;

}

.titleDate {
    font-weight: 500;
}

p {
    color: #626262;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
}

.time-label {
    /*color: #626262;*/
    color: #989898;
    font-weight: 400;
    font-family: 'Monserrat', monospace, sans-serif;
    font-size: 20px;
}

.App-body {
    /* background-color: #282c34; */
    background-color: whitesmoke;
    /*min-height: 80%;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*font-size: calc(10px + 2vmin);*/
    color: white;
}

button.btnForm.btn.btn-success {
    margin-right: 10px;
    background-color: #0084FB;
    border: 0;
    border-radius: 0;
    color: white;
    width: 120px;
    height: 45px;
}

button.btnForm.btn-success.disabled {
    background-color: #989898;
    border-color: #989898;
}

button.btnForm.btn.btn-warning {
    margin-right: 10px;
    background-color: #FF5D00;
    border: 0;
    border-radius: 0;
    color: white;
    width: 120px;
    height: 45px;
}

.inputClave {
    border: 1px solid black !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    text-align: center;

}

.labelForm {
    color: #626262;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    font-size: 18px;
    font-weight: 400;
}

.App-link {
    color: #61dafb;
}

.smallForm {
    padding-top: 10%;
    font-size: 12px;
    color: gray;
}

.hidden {
    display: none;
}

.loading-spinner {
    margin-top: 100px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}